import useMousePosition from './useMousePosition';
import React, { useState, useEffect, useRef } from 'react';
import useSound from 'use-sound';
import button from './Sounds/click.m4a';
import Metadata from './ReplicateImages/metadata.json';
import moment from 'moment';
import { NavLink } from "react-router-dom";
import { useFetch } from './Hooks/useFetch';
import { RangeSlider } from './Hooks/RangeSlider';
import Popup from './ArchivePopup';
import LazyLoad from 'parm-react-lazyload';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { AsyncImage } from 'loadable-image'

import { AiFillHeart, AiFillInstagram } from 'react-icons/ai';
import Switch from './Switch';
import CreateWidget from './CreateWidget';
import { Flipper, Flipped } from 'react-flip-toolkit'
import { FaXTwitter } from "react-icons/fa6";
import { AiOutlineCamera,AiFillCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { BsChatSquareText, BsCamera, BsEmojiHeartEyes, BsEmojiAngry, BsXOctagon, BsInstagram, BsChevronDown, BsCheck } from "react-icons/bs";
import { animated, useSpring } from 'react-spring';
import InfiniteScroll from "react-infinite-scroll-component";

import socketIO from 'socket.io-client';
import pfp0 from './Img/Thumbs/0.png'
import pfp1 from './Img/Thumbs/1.png'
import pfp2 from './Img/Thumbs/2.png'
import pfp3 from './Img/Thumbs/3.png'

const socket = socketIO.connect('https://api.jakobseeder.pro');

function importAll(r) {
    return r.keys().map(r);
  }
function ImgBox(img, i) {
    return (
        <img key={i} src={img} alt="info"></img>
    )
}
function Img(img) {
  const [isLoaded, setLoaded] = useState(false);
  return(
    <>
    <AsyncImage
      src={img.img}
      style={{ width: img.size, height: img.size }}
      className={img.addMargin && "addMargin"}
    />
    </>
  )
}
function Feed(props) {
    const pfps = [pfp0, pfp1, pfp2, pfp3];
    const [play, {stop}] = useSound(
        button,
        { volume: 0.1 }
      );
    
    const [popupData, setPopupData] = useState({});
    const [popup, setPopup] = useState(false);
      //Fetch data from backend
    const [isLoading, setLoading] = useState(false);
    const [isNSFW, setNSFW] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [metadata, setMetadata] = useState({});
    const [sliderVal, setSliderVal] = useState(1);
    const timeago = moment.unix(props.data.timestamp);
    const imgbox = useRef(null);
    const [imgbox_w, setImgbox_w] = useState(0);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [index, setIndex] = useState(2);
    const [postLimit, setPostLimit] = useState(15);
    const contentRef = useRef(null)
    const [contentWidth, setContentWidth] = useState(0);

    var data2;
    useEffect(() => {
      socket.off('refresh_feed');
      socket.on('refresh_feed', (newpost) => {
        console.log("refreshed")
        props.pushPost(newpost);
      })
    }, [socket]);
    useEffect(() => {
      setContentWidth(contentRef.current.clientWidth - 0);
    }, []);
    useEffect(() => {
      setImgbox_w(imgbox.current ? imgbox.current.offsetWidth : 0)
    }, [imgbox.current, sliderVal]);
    const observerTarget = useRef(null);
    useEffect(() => {
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            setPostLimit(prevPostLimit => prevPostLimit+15)
          }
        },
        { threshold: 1 }
      );

      if (observerTarget.current) {
        observer.observe(observerTarget.current);
      }

      return () => {
        if (observerTarget.current) {
          observer.unobserve(observerTarget.current);
        }
      };
    }, [observerTarget]);
    return (
      <>
        <div className={popup ? "new-grid blur" : "new-grid"}>
        <CreateWidget pushPost={props.pushPost} />
            <div className={sliderVal < 6 ? "frame feed" : "frame"}>
            {!isLoading &&
                <div className={isNSFW ? "inner nsfw" : "inner"}>
                  <Flipper flipKey={props.data.map(r => r._id).join('')} spring={{ stiffness: 200, damping: 22 }} onStart={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth' })}>
            {props.data.sort((a, b) => b.timestamp.valueOf()-a.timestamp.valueOf()).slice(0, postLimit).map(
                      (image, index) =>  
                      <Flipped flipId={image._id} key={image._id}>
                        <div className={'img-box '+image.contenttype} ref={image.nsfw ? null : imgbox} 
                        onMouseEnter={() => {
                          setIsHovering(true);
                          setMetadata({
                            "prompt": image.prompt,
                            "time": moment.unix(image.timestamp, "x").fromNow()
                          })
                          /*setTimeout(() => {
                            {isHovering &&
                              play();
                            }
                          }, 100);*/
                          
                        }}
                        onMouseLeave={() => {
                          setIsHovering(false);
                          stop();
                        }}>
                        <div className="pfp">
                            <img src={pfps[image.pfp]}></img>
                          </div>
                        <div className='content' ref={contentRef}>
                        <div className='profile_header'>
                          <div className="handle">jakob sitter</div><div className='time'>@jakobsitter · {moment.unix(image.timestamp, "x").fromNow()}
                          {image.instagram_url &&
                            <div className='social_icon'>
                            <a href={image.instagram_url} target="_blank"><BsInstagram /></a>
                            </div>
                          }
                          {image.tweet_id &&
                            <div className='social_icon'>
                            <a href={"https://twitter.com/jakobsitter/status/"+image.tweet_id} target="_blank"><FaXTwitter /></a>
                            </div>
                          }
                          </div>
                        </div>
                        <div className='body'>
                        {image.caption}
                        </div>
                        {image.contenttype == 'image' &&
                          <Img size={contentWidth} img={image.bucket_url} addMargin={image.caption ? true : false} />
                        }               
                        </div>
                        </div>
                       </Flipped>
                    )
            }
              <div ref={observerTarget}></div>
            </Flipper>
            </div>}
        </div>
        </div>
        </>
    );
}
export default Feed;